import React from 'react';

type Props = {
    className?: string;
};

const IconTwitter: React.FC<Props> = ({ className }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" className={`icon ${className}`}>
        <path
            d="M22 5.62649C21.2641 5.95283 20.4733 6.1734 19.6433 6.2726C20.4905 5.76475 21.1411 4.96063 21.4475 4.00251C20.6547 4.47268 19.7765 4.81417 18.8419 4.99823C18.0933 4.20076 17.027 3.70242 15.8468 3.70242C13.5807 3.70242 11.7434 5.53958 11.7434 7.80557C11.7434 8.12716 11.7798 8.44034 11.8497 8.74065C8.43952 8.56954 5.41607 6.93597 3.3923 4.45351C3.0391 5.05953 2.83675 5.76439 2.83675 6.51635C2.83675 7.93989 3.56121 9.19582 4.6622 9.93162C3.98954 9.91033 3.35689 9.72576 2.80361 9.41843C2.80332 9.43555 2.80332 9.45274 2.80332 9.47001C2.80332 11.4581 4.21771 13.1165 6.09481 13.4934C5.75047 13.5872 5.38798 13.6373 5.01372 13.6373C4.74933 13.6373 4.49226 13.6116 4.24178 13.5637C4.76389 15.1939 6.27923 16.3802 8.07476 16.4133C6.67047 17.5138 4.9012 18.1698 2.97882 18.1698C2.64765 18.1698 2.32101 18.1504 2 18.1125C3.81587 19.2767 5.97271 19.956 8.28992 19.956C15.8372 19.956 19.9644 13.7036 19.9644 8.28138C19.9644 8.10346 19.9604 7.9265 19.9525 7.75056C20.7542 7.17205 21.4499 6.44934 22 5.62649Z"
            fill="white"
        />
    </svg>
);

export default IconTwitter;
