export enum ctaSections {
    TLDR = 'tldr',
    EVOLUTION = 'skateboarding-evolution',
    ARCHIVING = 'archiving',
    ABOUT = 'about-us',
    DONE = 'done',
    HARDWARE = 'hardware-device',
    KRAKAPP = 'krak-app',
    KRAKBOX = 'krakbox',
    KRAKMAG = 'krakmag',
    YOUTUBE = 'youtube-channel',
    COLLABS = 'collabs-and-merch',
    EVENTS = 'events',
    KRAKSESSION = 'krak-session-app',
    FEED = 'video-and-news-feed',
    MAP = 'skatespots-map',
    DISCORD = 'discord-community',
    VISION = 'vision',
    DAO = 'dao',
    FINAL = 'final',
}
