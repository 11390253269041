import React from 'react';

type Props = {
    className?: string;
};

const IconUserCircle: React.FC<Props> = ({ className }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" className={`icon ${className}`}>
        <path
            d="M12 15.375C14.2782 15.375 16.125 13.5282 16.125 11.25C16.125 8.97183 14.2782 7.125 12 7.125C9.72183 7.125 7.875 8.97183 7.875 11.25C7.875 13.5282 9.72183 15.375 12 15.375Z"
            fill="white"
        />
        <path
            d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7471 9.41504 20.7189 6.93679 18.8911 5.10894C17.0632 3.28109 14.585 2.25293 12 2.25ZM18.1648 17.476C17.5628 16.6037 16.782 15.8695 15.8744 15.3221C14.8308 16.3188 13.4432 16.875 12 16.875C10.5569 16.875 9.16923 16.3188 8.12558 15.3221C7.218 15.8695 6.43724 16.6037 5.83516 17.476C4.77814 16.2874 4.08725 14.8186 3.84568 13.2464C3.60411 11.6743 3.82217 10.0658 4.4736 8.6147C5.12502 7.16361 6.18204 5.93177 7.51737 5.06752C8.85269 4.20327 10.4094 3.74346 12 3.74346C13.5906 3.74346 15.1473 4.20327 16.4826 5.06752C17.8179 5.93177 18.875 7.16361 19.5264 8.6147C20.1778 10.0658 20.3959 11.6743 20.1543 13.2464C19.9127 14.8186 19.2218 16.2874 18.1648 17.476Z"
            fill="white"
        />
    </svg>
);

export default IconUserCircle;
